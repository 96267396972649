import {
  GET_ALL_ACTIVE_SOCIAL_LINKS_REQUEST,
  GET_ALL_ACTIVE_SOCIAL_LINKS_SUCCESS,
  GET_ALL_ACTIVE_SOCIAL_LINKS_FAIL,
  GET_ALL_SOCIAL_LINKS_REQUEST,
  GET_ALL_SOCIAL_LINKS_SUCCESS,
  GET_ALL_SOCIAL_LINKS_FAIL,
  GET_SOCIAL_LINKS_BY_ID_REQUEST,
  GET_SOCIAL_LINKS_BY_ID_SUCCESS,
  GET_SOCIAL_LINKS_BY_ID_FAIL,
  CREATE_SOCIAL_LINKS_REQUEST,
  CREATE_SOCIAL_LINKS_SUCCESS,
  CREATE_SOCIAL_LINKS_FAIL,
  UPDATE_SOCIAL_LINKS_REQUEST,
  UPDATE_SOCIAL_LINKS_SUCCESS,
  UPDATE_SOCIAL_LINKS_FAIL,
  DELETE_SOCIAL_LINKS_REQUEST,
  DELETE_SOCIAL_LINKS_SUCCESS,
  DELETE_SOCIAL_LINKS_FAIL,
} from "../constant/socialLinks.constant";

const initialState = {
  loading: false,
  socialLinks: [],
  socialLink: null,
  error: null,
};

export const getAllActiveSocialLinksReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ALL_ACTIVE_SOCIAL_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_ACTIVE_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        socialLinks: action.payload,
        error: null,
      };
    case GET_ALL_ACTIVE_SOCIAL_LINKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllSocialLinksReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ALL_SOCIAL_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        socialLinks: action.payload,
        error: null,
      };
    case GET_ALL_SOCIAL_LINKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getSocialLinksByIdReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_SOCIAL_LINKS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SOCIAL_LINKS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        socialLink: action.payload,
        error: null,
      };
    case GET_SOCIAL_LINKS_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createSocialLinksReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CREATE_SOCIAL_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        socialLink: action.payload,
        error: null,
      };
    case CREATE_SOCIAL_LINKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateSocialLinksReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_SOCIAL_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        socialLink: action.payload,
        error: null,
      };
    case UPDATE_SOCIAL_LINKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteSocialLinksReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case DELETE_SOCIAL_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        socialLink: action.payload,
        error: null,
      };
    case DELETE_SOCIAL_LINKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
