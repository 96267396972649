import {
  GET_ACTIVE_SHARE_PICTURE_REQUEST,
  GET_ACTIVE_SHARE_PICTURE_SUCCESS,
  GET_ACTIVE_SHARE_PICTURE_FAIL,
  GET_ALL_SHARE_PICTURES_REQUEST,
  GET_ALL_SHARE_PICTURES_SUCCESS,
  GET_ALL_SHARE_PICTURES_FAIL,
  CREATE_SHARE_PICTURE_REQUEST,
  CREATE_SHARE_PICTURE_SUCCESS,
  CREATE_SHARE_PICTURE_FAIL,
  UPDATE_SHARE_PICTURE_REQUEST,
  UPDATE_SHARE_PICTURE_SUCCESS,
  UPDATE_SHARE_PICTURE_FAIL,
  DELETE_SHARE_PICTURE_REQUEST,
  DELETE_SHARE_PICTURE_SUCCESS,
  DELETE_SHARE_PICTURE_FAIL,
  GET_SHARE_PICTURE_SUCCESS,
  GET_SHARE_PICTURE_REQUEST,
  GET_SHARE_PICTURE_FAIL,
} from "../constant/picture.constant";

const initialState = {
  loading: false,
  sharePictures: [],
  sharePicture: null,
  error: null,
};

export const getActiveSharePictureReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ACTIVE_SHARE_PICTURE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ACTIVE_SHARE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        sharePicture: action.payload,
        error: null,
      };
    case GET_ACTIVE_SHARE_PICTURE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllSharePicturesReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ALL_SHARE_PICTURES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_SHARE_PICTURES_SUCCESS:
      return {
        ...state,
        loading: false,
        sharePictures: action.payload,
        error: null,
      };
    case GET_ALL_SHARE_PICTURES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getSharePictureByIdReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_SHARE_PICTURE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SHARE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        sharePicture: action.payload,
        error: null,
      };
    case GET_SHARE_PICTURE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createSharePictureReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CREATE_SHARE_PICTURE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_SHARE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        sharePicture: action.payload,
        error: null,
      };
    case CREATE_SHARE_PICTURE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateSharePictureReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_SHARE_PICTURE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_SHARE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        sharePicture: action.payload,
        error: null,
      };
    case UPDATE_SHARE_PICTURE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteSharePictureReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case DELETE_SHARE_PICTURE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_SHARE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        sharePicture: action.payload,
        error: null,
      };
    case DELETE_SHARE_PICTURE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
