export const GET_ALL_ROLES_REQUEST = "GET_ALL_ROLES_REQUEST";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const GET_ALL_ROLES_FAIL = "GET_ALL_ROLES_FAIL";

export const GET_ROLE_BY_ID_REQUEST = "GET_ROLE_BY_ID_REQUEST";
export const GET_ROLE_BY_ID_SUCCESS = "GET_ROLE_BY_ID_SUCCESS";
export const GET_ROLE_BY_ID_FAIL = "GET_ROLE_BY_ID_FAIL";

export const CREATE_ROLE_REQUEST = "CREATE_ROLE_REQUEST";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAIL = "CREATE_ROLE_FAIL";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";
