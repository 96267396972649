import axios from "axios";
import {
  GET_ALL_ROLES_REQUEST,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAIL,
  GET_ROLE_BY_ID_REQUEST,
  GET_ROLE_BY_ID_SUCCESS,
  GET_ROLE_BY_ID_FAIL,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAIL,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
} from "../constant/role.constant";
import { UseToastOptions } from "@chakra-ui/react";
import { handleErrorMessage } from "../../interceptor/ErrorHandler";
import API_BASE_URL from "../../util/config";
import { getConfig } from "./common/action";

export const getAllRoles =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ALL_ROLES_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}roles`, config);
      dispatch({ type: GET_ALL_ROLES_SUCCESS, payload: data });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ALL_ROLES_FAIL
      );
    }
  };

export const getRoleById =
  (
    id: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ROLE_BY_ID_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}roles/${id}`, config);
      dispatch({ type: GET_ROLE_BY_ID_SUCCESS, payload: data });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ROLE_BY_ID_FAIL
      );
    }
  };
export const createRole =
  (
    role: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: CREATE_ROLE_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.post(`${API_BASE_URL}roles`, role, config);
      dispatch({ type: CREATE_ROLE_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `Role created successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        CREATE_ROLE_FAIL
      );
    }
  };

export const updateRole =
  (
    id: string,
    role: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: UPDATE_ROLE_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.put(
        `${API_BASE_URL}roles/${id}`,
        role,
        config
      );
      dispatch({ type: UPDATE_ROLE_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `Role updated successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        UPDATE_ROLE_FAIL
      );
    }
  };

export const deleteRole =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: DELETE_ROLE_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.delete(`${API_BASE_URL}roles/${id}`, config);
      dispatch({ type: DELETE_ROLE_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `Role deleted successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        DELETE_ROLE_FAIL
      );
    }
  };
