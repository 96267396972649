export const formatTime = (timeInSeconds: number): string => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};

export function truncateWords(text: string, wordCount: number) {
  if (!text) return "";
  const actualLetterCount = text.length;
  if (actualLetterCount <= wordCount) {
    return text;
  }
  const truncatedText = text.slice(0, wordCount);
  return truncatedText + " . . .";
}

export const formatNumber = (
  number: number,
  locale: string = "en-US"
): string => {
  return new Intl.NumberFormat(locale).format(number);
};

export const formatDate = (dateInput: any) => {
  const dateString = dateInput;
  if (dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().substr(0, 10);
    return formattedDate;
  }
  return 0;
};

export const qrCodeURL = (params: string) => {
  return window.location.origin + "/#/" + params;
};
