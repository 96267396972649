export const GET_ABOUT_US_REQUEST = "GET_ABOUT_US_REQUEST";
export const GET_ABOUT_US_SUCCESS = "GET_ABOUT_US_SUCCESS";
export const GET_ABOUT_US_FAIL = "GET_ABOUT_US_FAIL";

export const GET_ACTIVE_ABOUT_US_REQUEST = "GET_ACTIVE_ABOUT_US_REQUEST";
export const GET_ACTIVE_ABOUT_US_SUCCESS = "GET_ACTIVE_ABOUT_US_SUCCESS";
export const GET_ACTIVE_ABOUT_US_FAIL = "GET_ACTIVE_ABOUT_US_FAIL";

export const CREATE_ABOUT_US_REQUEST = "CREATE_ABOUT_US_REQUEST";
export const CREATE_ABOUT_US_SUCCESS = "CREATE_ABOUT_US_SUCCESS";
export const CREATE_ABOUT_US_FAIL = "CREATE_ABOUT_US_FAIL";

export const UPDATE_ABOUT_US_REQUEST = "UPDATE_ABOUT_US_REQUEST";
export const UPDATE_ABOUT_US_SUCCESS = "UPDATE_ABOUT_US_SUCCESS";
export const UPDATE_ABOUT_US_FAIL = "UPDATE_ABOUT_US_FAIL";

export const DELETE_ABOUT_US_REQUEST = "DELETE_ABOUT_US_REQUEST";
export const DELETE_ABOUT_US_SUCCESS = "DELETE_ABOUT_US_SUCCESS";
export const DELETE_ABOUT_US_FAIL = "DELETE_ABOUT_US_FAIL";
