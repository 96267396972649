import {
  GET_ALL_PERMISSIONS_REQUEST,
  GET_ALL_PERMISSIONS_SUCCESS,
  GET_ALL_PERMISSIONS_FAIL,
  GET_ACTIVE_PERMISSIONS_REQUEST,
  GET_ACTIVE_PERMISSIONS_SUCCESS,
  GET_ACTIVE_PERMISSIONS_FAIL,
  GET_PERMISSION_BY_ID_REQUEST,
  GET_PERMISSION_BY_ID_SUCCESS,
  GET_PERMISSION_BY_ID_FAIL,
  CREATE_PERMISSION_REQUEST,
  CREATE_PERMISSION_SUCCESS,
  CREATE_PERMISSION_FAIL,
  UPDATE_PERMISSION_REQUEST,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_FAIL,
  DELETE_PERMISSION_REQUEST,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAIL,
} from "../constant/permission.constant";

const initialState = {
  loading: false,
  permissions: [],
  permission: null,
  error: null,
};

export const getAllPermissionsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ALL_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        permissions: action.payload,
        error: null,
      };
    case GET_ALL_PERMISSIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getActivePermissionsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ACTIVE_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ACTIVE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        permissions: action.payload,
        error: null,
      };
    case GET_ACTIVE_PERMISSIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getPermissionByIdReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_PERMISSION_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PERMISSION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        permission: action.payload,
        error: null,
      };
    case GET_PERMISSION_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createPermissionReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CREATE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        permission: action.payload,
        error: null,
      };
    case CREATE_PERMISSION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updatePermissionReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        permission: action.payload,
        error: null,
      };
    case UPDATE_PERMISSION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deletePermissionReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case DELETE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_PERMISSION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
