export const GET_ACTIVE_SHARE_PICTURE_REQUEST =
  "GET_ACTIVE_SHARE_PICTURE_REQUEST";
export const GET_ACTIVE_SHARE_PICTURE_SUCCESS =
  "GET_ACTIVE_SHARE_PICTURE_SUCCESS";
export const GET_ACTIVE_SHARE_PICTURE_FAIL = "GET_ACTIVE_SHARE_PICTURE_FAIL";

export const GET_ALL_SHARE_PICTURES_REQUEST = "GET_ALL_SHARE_PICTURES_REQUEST";
export const GET_ALL_SHARE_PICTURES_SUCCESS = "GET_ALL_SHARE_PICTURES_SUCCESS";
export const GET_ALL_SHARE_PICTURES_FAIL = "GET_ALL_SHARE_PICTURES_FAIL";

export const GET_SHARE_PICTURE_REQUEST = "GET_SHARE_PICTURE_REQUEST";
export const GET_SHARE_PICTURE_SUCCESS = "GET_SHARE_PICTURE_SUCCESS";
export const GET_SHARE_PICTURE_FAIL = "GET_SHARE_PICTURE_FAIL";

export const CREATE_SHARE_PICTURE_REQUEST = "CREATE_SHARE_PICTURE_REQUEST";
export const CREATE_SHARE_PICTURE_SUCCESS = "CREATE_SHARE_PICTURE_SUCCESS";
export const CREATE_SHARE_PICTURE_FAIL = "CREATE_SHARE_PICTURE_FAIL";

export const UPDATE_SHARE_PICTURE_REQUEST = "UPDATE_SHARE_PICTURE_REQUEST";
export const UPDATE_SHARE_PICTURE_SUCCESS = "UPDATE_SHARE_PICTURE_SUCCESS";
export const UPDATE_SHARE_PICTURE_FAIL = "UPDATE_SHARE_PICTURE_FAIL";

export const DELETE_SHARE_PICTURE_REQUEST = "DELETE_SHARE_PICTURE_REQUEST";
export const DELETE_SHARE_PICTURE_SUCCESS = "DELETE_SHARE_PICTURE_SUCCESS";
export const DELETE_SHARE_PICTURE_FAIL = "DELETE_SHARE_PICTURE_FAIL";
