import {
  GET_ABOUT_US_REQUEST,
  GET_ABOUT_US_SUCCESS,
  GET_ABOUT_US_FAIL,
  GET_ACTIVE_ABOUT_US_REQUEST,
  GET_ACTIVE_ABOUT_US_SUCCESS,
  GET_ACTIVE_ABOUT_US_FAIL,
  CREATE_ABOUT_US_REQUEST,
  CREATE_ABOUT_US_SUCCESS,
  CREATE_ABOUT_US_FAIL,
  UPDATE_ABOUT_US_REQUEST,
  UPDATE_ABOUT_US_SUCCESS,
  UPDATE_ABOUT_US_FAIL,
  DELETE_ABOUT_US_REQUEST,
  DELETE_ABOUT_US_SUCCESS,
  DELETE_ABOUT_US_FAIL,
} from "../constant/aboutus.constant";

const initialState = {
  loading: false,
  aboutUs: null,
  error: null,
};

export const getAboutUsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ABOUT_US_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ABOUT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutUs: action.payload,
        error: null,
      };
    case GET_ABOUT_US_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getActiveAboutUsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ACTIVE_ABOUT_US_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ACTIVE_ABOUT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutUs: action.payload,
        error: null,
      };
    case GET_ACTIVE_ABOUT_US_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createAboutUsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CREATE_ABOUT_US_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_ABOUT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutUs: action.payload,
        error: null,
      };
    case CREATE_ABOUT_US_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateAboutUsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_ABOUT_US_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_ABOUT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutUs: action.payload,
        error: null,
      };
    case UPDATE_ABOUT_US_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteAboutUsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case DELETE_ABOUT_US_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_ABOUT_US_SUCCESS:
      return {
        ...state,
        aboutUs: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_ABOUT_US_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
