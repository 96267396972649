// Permission Constants
export const GET_ALL_PERMISSIONS_REQUEST = "GET_ALL_PERMISSIONS_REQUEST";
export const GET_ALL_PERMISSIONS_SUCCESS = "GET_ALL_PERMISSIONS_SUCCESS";
export const GET_ALL_PERMISSIONS_FAIL = "GET_ALL_PERMISSIONS_FAIL";

export const GET_ACTIVE_PERMISSIONS_REQUEST = "GET_ACTIVE_PERMISSIONS_REQUEST";
export const GET_ACTIVE_PERMISSIONS_SUCCESS = "GET_ACTIVE_PERMISSIONS_SUCCESS";
export const GET_ACTIVE_PERMISSIONS_FAIL = "GET_ACTIVE_PERMISSIONS_FAIL";

export const GET_PERMISSION_BY_ID_REQUEST = "GET_PERMISSION_BY_ID_REQUEST";
export const GET_PERMISSION_BY_ID_SUCCESS = "GET_PERMISSION_BY_ID_SUCCESS";
export const GET_PERMISSION_BY_ID_FAIL = "GET_PERMISSION_BY_ID_FAIL";

export const CREATE_PERMISSION_REQUEST = "CREATE_PERMISSION_REQUEST";
export const CREATE_PERMISSION_SUCCESS = "CREATE_PERMISSION_SUCCESS";
export const CREATE_PERMISSION_FAIL = "CREATE_PERMISSION_FAIL";

export const UPDATE_PERMISSION_REQUEST = "UPDATE_PERMISSION_REQUEST";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAIL = "UPDATE_PERMISSION_FAIL";

export const DELETE_PERMISSION_REQUEST = "DELETE_PERMISSION_REQUEST";
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_FAIL = "DELETE_PERMISSION_FAIL";
