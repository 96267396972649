import {
  GET_ALL_ROLES_REQUEST,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAIL,
  GET_ROLE_BY_ID_REQUEST,
  GET_ROLE_BY_ID_SUCCESS,
  GET_ROLE_BY_ID_FAIL,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAIL,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
} from "../constant/role.constant";

const initialState = {
  loading: false,
  roles: [],
  role: null,
  error: null,
};

export const getAllRolesReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ALL_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload,
        error: null,
      };
    case GET_ALL_ROLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getRoleByIdReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ROLE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        role: action.payload,
        error: null,
      };
    case GET_ROLE_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createRoleReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CREATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        role: action.payload,
        error: null,
      };
    case CREATE_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateRoleReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        role: action.payload,
        error: null,
      };
    case UPDATE_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteRoleReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case DELETE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        role: action.payload,
        error: null,
      };
    case DELETE_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
