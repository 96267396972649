import axios from "axios";
import {
  GET_ABOUT_US_REQUEST,
  GET_ABOUT_US_SUCCESS,
  GET_ABOUT_US_FAIL,
  GET_ACTIVE_ABOUT_US_REQUEST,
  GET_ACTIVE_ABOUT_US_SUCCESS,
  GET_ACTIVE_ABOUT_US_FAIL,
  CREATE_ABOUT_US_REQUEST,
  CREATE_ABOUT_US_SUCCESS,
  CREATE_ABOUT_US_FAIL,
  UPDATE_ABOUT_US_REQUEST,
  UPDATE_ABOUT_US_SUCCESS,
  UPDATE_ABOUT_US_FAIL,
  DELETE_ABOUT_US_REQUEST,
  DELETE_ABOUT_US_SUCCESS,
  DELETE_ABOUT_US_FAIL,
} from "../constant/aboutus.constant";
import { UseToastOptions } from "@chakra-ui/react";
import { handleErrorMessage } from "../../interceptor/ErrorHandler";
import { getConfig } from "./common/action";
import API_BASE_URL from "../../util/config";

export const getAboutUs =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ABOUT_US_REQUEST });
      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}aboutus`, config);
      dispatch({ type: GET_ABOUT_US_SUCCESS, payload: data });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ABOUT_US_FAIL
      );
    }
  };

export const getActiveAboutUs =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ACTIVE_ABOUT_US_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}aboutus/active`, config);
      dispatch({ type: GET_ACTIVE_ABOUT_US_SUCCESS, payload: data });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ACTIVE_ABOUT_US_FAIL
      );
    }
  };
export const createAboutUs =
  (
    aboutUs: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: CREATE_ABOUT_US_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.post(
        `${API_BASE_URL}aboutus`,
        aboutUs,
        config
      );
      dispatch({ type: CREATE_ABOUT_US_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `About Us created successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        CREATE_ABOUT_US_FAIL
      );
    }
  };

export const updateAboutUs =
  (
    aboutUs: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: UPDATE_ABOUT_US_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.put(
        `${API_BASE_URL}aboutus`,
        aboutUs,
        config
      );
      dispatch({ type: UPDATE_ABOUT_US_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `About Us updated successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        UPDATE_ABOUT_US_FAIL
      );
    }
  };

export const deleteAboutUs =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: DELETE_ABOUT_US_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.delete(`${API_BASE_URL}aboutus`, config);
      dispatch({ type: DELETE_ABOUT_US_SUCCESS, payload: data });

      // Add toast notification
      toasts({
        title: `About Us deleted successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        DELETE_ABOUT_US_FAIL
      );
    }
  };
