export const GET_ACTIVE_SHARE_TEXT_REQUEST = "GET_ACTIVE_SHARE_TEXT_REQUEST";
export const GET_ACTIVE_SHARE_TEXT_SUCCESS = "GET_ACTIVE_SHARE_TEXT_SUCCESS";
export const GET_ACTIVE_SHARE_TEXT_FAIL = "GET_ACTIVE_SHARE_TEXT_FAIL";

export const GET_ALL_SHARE_TEXTS_REQUEST = "GET_ALL_SHARE_TEXTS_REQUEST";
export const GET_ALL_SHARE_TEXTS_SUCCESS = "GET_ALL_SHARE_TEXTS_SUCCESS";
export const GET_ALL_SHARE_TEXTS_FAIL = "GET_ALL_SHARE_TEXTS_FAIL";

export const GET_SHARE_TEXT_BY_ID_REQUEST = "GET_SHARE_TEXT_BY_ID_REQUEST";
export const GET_SHARE_TEXT_BY_ID_SUCCESS = "GET_SHARE_TEXT_BY_ID_SUCCESS";
export const GET_SHARE_TEXT_BY_ID_FAIL = "GET_SHARE_TEXT_BY_ID_FAIL";

export const CREATE_SHARE_TEXT_REQUEST = "CREATE_SHARE_TEXT_REQUEST";
export const CREATE_SHARE_TEXT_SUCCESS = "CREATE_SHARE_TEXT_SUCCESS";
export const CREATE_SHARE_TEXT_FAIL = "CREATE_SHARE_TEXT_FAIL";

export const UPDATE_SHARE_TEXT_REQUEST = "UPDATE_SHARE_TEXT_REQUEST";
export const UPDATE_SHARE_TEXT_SUCCESS = "UPDATE_SHARE_TEXT_SUCCESS";
export const UPDATE_SHARE_TEXT_FAIL = "UPDATE_SHARE_TEXT_FAIL";

export const DELETE_SHARE_TEXT_REQUEST = "DELETE_SHARE_TEXT_REQUEST";
export const DELETE_SHARE_TEXT_SUCCESS = "DELETE_SHARE_TEXT_SUCCESS";
export const DELETE_SHARE_TEXT_FAIL = "DELETE_SHARE_TEXT_FAIL";
