import {
  GET_ALL_LANDING_REQUEST,
  GET_ALL_LANDING_SUCCESS,
  GET_ALL_LANDING_FAIL,
  GET_ALL_ACTIVE_LANDING_REQUEST,
  GET_ALL_ACTIVE_LANDING_SUCCESS,
  GET_ALL_ACTIVE_LANDING_FAIL,
  GET_LANDING_BY_ID_REQUEST,
  GET_LANDING_BY_ID_SUCCESS,
  GET_LANDING_BY_ID_FAIL,
  CREATE_LANDING_REQUEST,
  CREATE_LANDING_SUCCESS,
  CREATE_LANDING_FAIL,
  UPDATE_LANDING_REQUEST,
  UPDATE_LANDING_SUCCESS,
  UPDATE_LANDING_FAIL,
  DELETE_LANDING_REQUEST,
  DELETE_LANDING_SUCCESS,
  DELETE_LANDING_FAIL,
} from "../constant/landing.constant";

const initialState = {
  loading: false,
  landing: null,
  landings: [],
  error: null,
};

export const getAllLandingReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ALL_LANDING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_LANDING_SUCCESS:
      return {
        ...state,
        loading: false,
        landings: action.payload,
        error: null,
      };
    case GET_ALL_LANDING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllActiveLandingReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ALL_ACTIVE_LANDING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_ACTIVE_LANDING_SUCCESS:
      return {
        ...state,
        loading: false,
        landings: action.payload,
        error: null,
      };
    case GET_ALL_ACTIVE_LANDING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getLandingByIdReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_LANDING_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_LANDING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        landing: action.payload,
        error: null,
      };
    case GET_LANDING_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createLandingReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CREATE_LANDING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_LANDING_SUCCESS:
      return {
        ...state,
        loading: false,
        landing: action.payload,
        error: null,
      };
    case CREATE_LANDING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateLandingReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_LANDING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_LANDING_SUCCESS:
      return {
        ...state,
        loading: false,
        landing: action.payload,
        error: null,
      };
    case UPDATE_LANDING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteLandingReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case DELETE_LANDING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_LANDING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DELETE_LANDING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
