export const GET_ALL_ACTIVE_SOCIAL_LINKS_REQUEST =
  "GET_ALL_ACTIVE_SOCIAL_LINKS_REQUEST";
export const GET_ALL_ACTIVE_SOCIAL_LINKS_SUCCESS =
  "GET_ALL_ACTIVE_SOCIAL_LINKS_SUCCESS";
export const GET_ALL_ACTIVE_SOCIAL_LINKS_FAIL =
  "GET_ALL_ACTIVE_SOCIAL_LINKS_FAIL";

export const GET_ALL_SOCIAL_LINKS_REQUEST = "GET_ALL_SOCIAL_LINKS_REQUEST";
export const GET_ALL_SOCIAL_LINKS_SUCCESS = "GET_ALL_SOCIAL_LINKS_SUCCESS";
export const GET_ALL_SOCIAL_LINKS_FAIL = "GET_ALL_SOCIAL_LINKS_FAIL";

export const GET_SOCIAL_LINKS_BY_ID_REQUEST = "GET_SOCIAL_LINKS_BY_ID_REQUEST";
export const GET_SOCIAL_LINKS_BY_ID_SUCCESS = "GET_SOCIAL_LINKS_BY_ID_SUCCESS";
export const GET_SOCIAL_LINKS_BY_ID_FAIL = "GET_SOCIAL_LINKS_BY_ID_FAIL";

export const CREATE_SOCIAL_LINKS_REQUEST = "CREATE_SOCIAL_LINKS_REQUEST";
export const CREATE_SOCIAL_LINKS_SUCCESS = "CREATE_SOCIAL_LINKS_SUCCESS";
export const CREATE_SOCIAL_LINKS_FAIL = "CREATE_SOCIAL_LINKS_FAIL";

export const UPDATE_SOCIAL_LINKS_REQUEST = "UPDATE_SOCIAL_LINKS_REQUEST";
export const UPDATE_SOCIAL_LINKS_SUCCESS = "UPDATE_SOCIAL_LINKS_SUCCESS";
export const UPDATE_SOCIAL_LINKS_FAIL = "UPDATE_SOCIAL_LINKS_FAIL";

export const DELETE_SOCIAL_LINKS_REQUEST = "DELETE_SOCIAL_LINKS_REQUEST";
export const DELETE_SOCIAL_LINKS_SUCCESS = "DELETE_SOCIAL_LINKS_SUCCESS";
export const DELETE_SOCIAL_LINKS_FAIL = "DELETE_SOCIAL_LINKS_FAIL";
