import {
  GET_ACTIVE_SHARE_TEXT_REQUEST,
  GET_ACTIVE_SHARE_TEXT_SUCCESS,
  GET_ACTIVE_SHARE_TEXT_FAIL,
  GET_ALL_SHARE_TEXTS_REQUEST,
  GET_ALL_SHARE_TEXTS_SUCCESS,
  GET_ALL_SHARE_TEXTS_FAIL,
  GET_SHARE_TEXT_BY_ID_REQUEST,
  GET_SHARE_TEXT_BY_ID_SUCCESS,
  GET_SHARE_TEXT_BY_ID_FAIL,
  CREATE_SHARE_TEXT_REQUEST,
  CREATE_SHARE_TEXT_SUCCESS,
  CREATE_SHARE_TEXT_FAIL,
  UPDATE_SHARE_TEXT_REQUEST,
  UPDATE_SHARE_TEXT_SUCCESS,
  UPDATE_SHARE_TEXT_FAIL,
  DELETE_SHARE_TEXT_REQUEST,
  DELETE_SHARE_TEXT_SUCCESS,
  DELETE_SHARE_TEXT_FAIL,
} from "../constant/text.constatnt";

const initialState = {
  loading: false,
  shareTexts: [],
  shareText: null,
  error: null,
};

export const getActiveShareTextReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ACTIVE_SHARE_TEXT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ACTIVE_SHARE_TEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        shareText: action.payload,
        error: null,
      };
    case GET_ACTIVE_SHARE_TEXT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllShareTextsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_ALL_SHARE_TEXTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_SHARE_TEXTS_SUCCESS:
      return {
        ...state,
        loading: false,
        shareTexts: action.payload,
        error: null,
      };
    case GET_ALL_SHARE_TEXTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getShareTextByIdReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case GET_SHARE_TEXT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SHARE_TEXT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        shareText: action.payload,
        error: null,
      };
    case GET_SHARE_TEXT_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createShareTextReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case CREATE_SHARE_TEXT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_SHARE_TEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        shareText: action.payload,
        error: null,
      };
    case CREATE_SHARE_TEXT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateShareTextReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case UPDATE_SHARE_TEXT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_SHARE_TEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        shareText: action.payload,
        error: null,
      };
    case UPDATE_SHARE_TEXT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteShareTextReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case DELETE_SHARE_TEXT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_SHARE_TEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        shareText: action.payload,
        error: null,
      };
    case DELETE_SHARE_TEXT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
