import { SET_TOAST_POSITION } from "../constant/setting.constant";

export const toastPositionReducer = (
  state = "top-right",
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case SET_TOAST_POSITION:
      return action.payload;
    default:
      return state;
  }
};
