// Constants for getting active share video by ID
export const GET_ACTIVE_SHARE_VIDEO_BY_ID_REQUEST = "GET_ACTIVE_SHARE_VIDEO_BY_ID_REQUEST";
export const GET_ACTIVE_SHARE_VIDEO_BY_ID_SUCCESS = "GET_ACTIVE_SHARE_VIDEO_BY_ID_SUCCESS";
export const GET_ACTIVE_SHARE_VIDEO_BY_ID_FAIL = "GET_ACTIVE_SHARE_VIDEO_BY_ID_FAIL";

// Constants for getting all share videos
export const GET_ALL_SHARE_VIDEOS_REQUEST = "GET_ALL_SHARE_VIDEOS_REQUEST";
export const GET_ALL_SHARE_VIDEOS_SUCCESS = "GET_ALL_SHARE_VIDEOS_SUCCESS";
export const GET_ALL_SHARE_VIDEOS_FAIL = "GET_ALL_SHARE_VIDEOS_FAIL";

// Constants for getting share video by ID
export const GET_SHARE_VIDEO_BY_ID_REQUEST = "GET_SHARE_VIDEO_BY_ID_REQUEST";
export const GET_SHARE_VIDEO_BY_ID_SUCCESS = "GET_SHARE_VIDEO_BY_ID_SUCCESS";
export const GET_SHARE_VIDEO_BY_ID_FAIL = "GET_SHARE_VIDEO_BY_ID_FAIL";

// Constants for creating a share video
export const CREATE_SHARE_VIDEO_REQUEST = "CREATE_SHARE_VIDEO_REQUEST";
export const CREATE_SHARE_VIDEO_SUCCESS = "CREATE_SHARE_VIDEO_SUCCESS";
export const CREATE_SHARE_VIDEO_FAIL = "CREATE_SHARE_VIDEO_FAIL";

// Constants for updating a share video
export const UPDATE_SHARE_VIDEO_REQUEST = "UPDATE_SHARE_VIDEO_REQUEST";
export const UPDATE_SHARE_VIDEO_SUCCESS = "UPDATE_SHARE_VIDEO_SUCCESS";
export const UPDATE_SHARE_VIDEO_FAIL = "UPDATE_SHARE_VIDEO_FAIL";

// Constants for deleting a share video
export const DELETE_SHARE_VIDEO_REQUEST = "DELETE_SHARE_VIDEO_REQUEST";
export const DELETE_SHARE_VIDEO_SUCCESS = "DELETE_SHARE_VIDEO_SUCCESS";
export const DELETE_SHARE_VIDEO_FAIL = "DELETE_SHARE_VIDEO_FAIL";
