import { UseToastOptions } from "@chakra-ui/react";
import { USER_LOGOUT, RESET_ALL_STATES } from "../Redux/constant/user.constant";

export const handleErrorMessage = (
  toasts: (options: UseToastOptions | undefined) => void,
  toastPosition: any,
  dispatch: {
    (arg0: { type: string; payload?: any }): void;
  },
  error: any,
  actionType: string
) => {
  const message =
    error.response?.data?.msg ||
    error.response?.data?.message ||
    error.response?.data?.error ||
    error.response?.data ||
    error.response ||
    error.message;

  if (error?.response?.status === 405) {
    localStorage.removeItem("userInfo");

    dispatch({ type: USER_LOGOUT });
    dispatch({ type: RESET_ALL_STATES });
    return toasts({
      title: message,
      status: "error",
      duration: 8000,
      position: toastPosition ? toastPosition : "top-right",
      isClosable: true,
    });
  }

  dispatch({
    type: actionType,
    payload: message,
  });

  toasts({
    title: message?.toString(),
    status: "error",
    duration: 6000,
    position: toastPosition ? toastPosition : "top-right",
    isClosable: true,
  });
};
