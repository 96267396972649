 export const GET_ALL_LANDING_REQUEST = 'GET_ALL_LANDING_REQUEST';
export const GET_ALL_LANDING_SUCCESS = 'GET_ALL_LANDING_SUCCESS';
export const GET_ALL_LANDING_FAIL = 'GET_ALL_LANDING_FAIL';

export const GET_ALL_ACTIVE_LANDING_REQUEST = 'GET_ALL_ACTIVE_LANDING_REQUEST';
export const GET_ALL_ACTIVE_LANDING_SUCCESS = 'GET_ALL_ACTIVE_LANDING_SUCCESS';
export const GET_ALL_ACTIVE_LANDING_FAIL = 'GET_ALL_ACTIVE_LANDING_FAIL';

export const GET_LANDING_BY_ID_REQUEST = 'GET_LANDING_BY_ID_REQUEST';
export const GET_LANDING_BY_ID_SUCCESS = 'GET_LANDING_BY_ID_SUCCESS';
export const GET_LANDING_BY_ID_FAIL = 'GET_LANDING_BY_ID_FAIL';

export const CREATE_LANDING_REQUEST = 'CREATE_LANDING_REQUEST';
export const CREATE_LANDING_SUCCESS = 'CREATE_LANDING_SUCCESS';
export const CREATE_LANDING_FAIL = 'CREATE_LANDING_FAIL';

export const UPDATE_LANDING_REQUEST = 'UPDATE_LANDING_REQUEST';
export const UPDATE_LANDING_SUCCESS = 'UPDATE_LANDING_SUCCESS';
export const UPDATE_LANDING_FAIL = 'UPDATE_LANDING_FAIL';

export const DELETE_LANDING_REQUEST = 'DELETE_LANDING_REQUEST';
export const DELETE_LANDING_SUCCESS = 'DELETE_LANDING_SUCCESS';
export const DELETE_LANDING_FAIL = 'DELETE_LANDING_FAIL';
