import {
  GET_ACTIVE_SHARE_VIDEO_BY_ID_REQUEST,
  GET_ACTIVE_SHARE_VIDEO_BY_ID_SUCCESS,
  GET_ACTIVE_SHARE_VIDEO_BY_ID_FAIL,
  GET_ALL_SHARE_VIDEOS_REQUEST,
  GET_ALL_SHARE_VIDEOS_SUCCESS,
  GET_ALL_SHARE_VIDEOS_FAIL,
  GET_SHARE_VIDEO_BY_ID_REQUEST,
  GET_SHARE_VIDEO_BY_ID_SUCCESS,
  GET_SHARE_VIDEO_BY_ID_FAIL,
  CREATE_SHARE_VIDEO_REQUEST,
  CREATE_SHARE_VIDEO_SUCCESS,
  CREATE_SHARE_VIDEO_FAIL,
  UPDATE_SHARE_VIDEO_REQUEST,
  UPDATE_SHARE_VIDEO_SUCCESS,
  UPDATE_SHARE_VIDEO_FAIL,
  DELETE_SHARE_VIDEO_REQUEST,
  DELETE_SHARE_VIDEO_SUCCESS,
  DELETE_SHARE_VIDEO_FAIL,
} from "../constant/video.constant";

interface ShareVideoState {
  loading: boolean;
  shareVideos: any[];
  shareVideo: any | null;
  error: string | null;
  message?: string;
}

const initialState: ShareVideoState = {
  loading: false,
  shareVideos: [],
  shareVideo: null,
  error: null,
};

export const getActiveShareVideoByIdReducer = (
  state = initialState,
  action: any
): ShareVideoState => {
  switch (action.type) {
    case GET_ACTIVE_SHARE_VIDEO_BY_ID_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_ACTIVE_SHARE_VIDEO_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        shareVideo: action.payload,
        error: null,
      };
    case GET_ACTIVE_SHARE_VIDEO_BY_ID_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getAllShareVideosReducer = (
  state = initialState,
  action: any
): ShareVideoState => {
  switch (action.type) {
    case GET_ALL_SHARE_VIDEOS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_ALL_SHARE_VIDEOS_SUCCESS:
      return {
        ...state,
        loading: false,
        shareVideos: action.payload,
        error: null,
      };
    case GET_ALL_SHARE_VIDEOS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getShareVideoByIdReducer = (
  state = initialState,
  action: any
): ShareVideoState => {
  switch (action.type) {
    case GET_SHARE_VIDEO_BY_ID_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SHARE_VIDEO_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        shareVideo: action.payload,
        error: null,
      };
    case GET_SHARE_VIDEO_BY_ID_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createShareVideoReducer = (
  state = initialState,
  action: any
): ShareVideoState => {
  switch (action.type) {
    case CREATE_SHARE_VIDEO_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_SHARE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        shareVideo: action.payload,
        error: null,
      };
    case CREATE_SHARE_VIDEO_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateShareVideoReducer = (
  state = initialState,
  action: any
): ShareVideoState => {
  switch (action.type) {
    case UPDATE_SHARE_VIDEO_REQUEST:
      return { ...state, loading: true, error: null };
    case UPDATE_SHARE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        shareVideo: action.payload,
        error: null,
      };
    case UPDATE_SHARE_VIDEO_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteShareVideoReducer = (
  state = initialState,
  action: any
): ShareVideoState => {
  switch (action.type) {
    case DELETE_SHARE_VIDEO_REQUEST:
      return { ...state, loading: true, error: null };
    case DELETE_SHARE_VIDEO_SUCCESS:
      return { ...state, loading: false, message: action.payload, error: null };
    case DELETE_SHARE_VIDEO_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
