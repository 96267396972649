import React from "react";
import { Flex, Box, Text, Icon, IconProps } from "@chakra-ui/react";
import { BiInfoCircle } from "react-icons/bi";

interface EmptyMessageProps {
  message?: string;
  icon?: React.ElementType<IconProps>;
}

const EmptyMessage: React.FC<EmptyMessageProps> = ({
  message = "No data available",
  icon: IconComponent = BiInfoCircle,
}) => {
  return (
    <Flex width="full" justifyContent="center">
      <Box textAlign="center" p={4}>
        <Icon as={IconComponent} boxSize={6} color="gray.400" mb={2} />
        <Text color="gray.400">{message}</Text>
      </Box>
    </Flex>
  );
};

export default EmptyMessage;
