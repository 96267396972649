import React from 'react';
import { Box, Icon, Text } from '@chakra-ui/react';
import { BiError } from 'react-icons/bi';

interface ErrorCardProps {
    message: string;
}

const ErrorCard: React.FC<ErrorCardProps> = ({ message }) => {
    return (
        <Box
            bg="red.200"
            color="red.800"
            border="1px solid"
            borderColor="red.400"
            borderRadius="10px"
            p={4}
            mt={4}
            w={'full'}
            textAlign={'center'}
        >
            <Icon as={BiError} boxSize={10} mb={0} />
            <Text fontWeight="bold" mb={0}>Error</Text>
            <Text>{message}</Text>
        </Box>
    );
};

export default ErrorCard;
